#root {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #242424;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  position: relative;
  flex: 1;
  max-width: 1200px;
  min-height: 750px;
  border-radius: 20px;
  overflow: hidden;
  background: linear-gradient(to top, #091530, #142959);
  display: flex;
  flex-direction: row;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.main-content {
  flex: 35;
  color: #fff;
  padding: 3rem 5rem;
}

.enrollment-form {
  flex: 35;
  color: #fff;
  padding: 3rem 5rem;
}

.splash-image {
  flex: 75;
  overflow: hidden;
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

.splash-image img {
  max-width: 100%;
  object-fit: cover;
  border: none;
  border-radius: 10px;
}

.logo {
  position: absolute;
  /* Set position to absolute */
  bottom: 20px;
  /* Adjust bottom position */
  right: 20px;
  /* Adjust right position */
  max-height: 125px;
  /* Adjust logo size */
  width: auto;
}

.phone-screens {
  max-height: 100%;
  /* Take 3/4th of the vertical space */
  height: auto;
  margin-bottom: 20px;
}

.divider {
  width: 80%;
  height: 0px;
  background-color: rgba(255, 255, 255, 0.3);
  /* Adjusted opacity */
  margin: 40px auto;
  /* Adjusted margin */
}

.section {
  margin-bottom: 5%;
  /* Adjusted margin */
}

.enroll-button,
.join-button {
  font-size: clamp(0.8rem, 1vw, 1.2rem);
  width: calc(100% - 40px);
  padding: 5% 5%;
  margin: 5% 0%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  text-align: left;
  position: relative;
  font-weight: bold;
}

.enroll-button {
  background: linear-gradient(to right, #4FA89E, #63D4C7);
  color: #fff;
  width: 100%;
}

.floating-login-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000; /* Ensures the button stays on top of other content */
  background-color: #0673c4; /* Example background color */
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
}

.join-button {
  background: linear-gradient(to right, #F07373, #E6AD6A);
  color: #fff;
  width: 100%;
}

.enroll-button:hover,
.join-button:hover {
  opacity: 0.8;
}

.icon {
  font-size: 3vw;
  /* Adjusted icon size */
  margin-right: 5px;
  /* Add right margin */
}

h1 {
  font-size: clamp(1.5rem, 3vw, 3rem);
  line-height: 1.35;
  color: #DBDBDB;
  margin-bottom: 20px;
}

.enrollment-form h1 {
  font-size: clamp(1rem, 2vw, 2rem);
  text-align: center;
}

h2 {
  font-size: clamp(1rem, 1.25vw, 1.5rem);
  font-weight: lighter;
  line-height: 1.5;
  color: #e8e8e8ad;
  letter-spacing: 1px;
  word-spacing: 3px;
  margin-bottom: 30px;
}

.summer-text {
  background: linear-gradient(to right, #F07373, #E6AD6A);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.enroll-text {
  background: linear-gradient(to right, #4FA89E, #63D4C7);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-fields {
  margin: 50px 0;
}

.form-field {
  border: 1px solid #4ca4e5;
  border-radius: 5px;
  padding: 10px;
  font-size: .85rem;
  margin-bottom: 10px;
  width: 300px;
}

.form-field-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.form-field-container label {
  width: 200px; /* Adjust this value to your preferred width */
  text-align: right;
  margin-right: 10px;
  font-size: 1rem; /* Adjust this value to your preferred size */
  font-weight: bold; /* Makes the text bold */
  color: #9ebcd5; /* Changes the text color */
  padding: 5px; /* Adds some padding */
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.goBack-button, .submit-enrollment-button, .join-mailing-list-button {
  font-size: clamp(0.8rem, 1.2vw, 1.5rem);
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  text-align: left;
  position: relative;
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  margin-right: 10px;
  height: 50px;
  justify-content: center;
}

.goBack-button {
  background: linear-gradient(to right, #D9B3FF, #A569BD);
  flex: 1;

}
.submit-enrollment-button {
  background: linear-gradient(to right, #4FA89E, #63D4C7);
  flex: 3
}

.join-mailing-list-button {
  background: linear-gradient(to right, #F07373, #E6AD6A);
  flex: 3
}

.required {
  color: red;
}

@media screen and (max-width: 768px) {
  #root {
    display: block;
  }
  .main-content {
    padding: 15% 5% 5%;
  }
  .container {
    flex-direction: column;
  }

  .logo {
    max-height: 75px;
  }
}